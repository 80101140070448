$raleway: 'Raleway',
sans-serif;
$noto: 'Raleway',
sans-serif;
$black: #000000;
$letter-spacing: 1px;
$letter-spacing-2: 2px;
$blue: #edf4f7;
$btn: #4396b4;
$highlight: #fdfc9f;
$question-blue: #4396b4;
$progress-gold: #f3d483;

body {
    font-size: 16px;
}

button {
    background-color: $btn;
    border-radius: 100px;
    padding: 10px 30px;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    border: none;
    letter-spacing: $letter-spacing-2;
    font-family: $raleway;
    outline: none;
}

h2 {
    font-size: 3.000em;
    font-weight: 300;
    font-family: $raleway;
    margin-top: 0px;
}

.cap-italic {
    font-size: 0.8em;
    margin-top: -20px;
    font-family: "Raleway,sans-serif", serif;
}

#site-header {
    position: relative;
    top: -23px;
}

.assessment_div {
    margin-top: -165px;
    .floating-controls {
        .float-close-btn {
            position: fixed;
            top: 30px;
            right: 50px;
            z-index: 1000000000;
            font-size: 24px;
            color: black;
            font-weight: 300;
        }
    }
}

.row {
    margin: 0px;
}

.background-blue {
    background-color: #edf4f7;
}

.yellow {
    background: url('../images/homepage/yellow.png');
    background-size: 100% 65%;
    background-repeat: no-repeat;
    background-position: left bottom;
}

.bold-text {
    font-weight: bold;
}

.content.content--disclaimer {
    margin-top: 90px;
}

#people-slideshow {
    .item {
        width: auto;
        min-width: auto;
        max-width: 100%;
        float: left;
        text-align: center;
        color: white;
        height: 324px;
        img {
            width: 100%;
        }
    }
}

#site-header {
    position: unset;
}

header {
    text-align: center;
    float: none !important;
    .logo-container {
        width: 100%;
        font-family: $raleway;
        color: $black;
        margin: 20px 0px;
        img {
            margin: 0 auto;
            width: 200px;
        }
        h1 {
            font-size: 1.875em;
            font-weight: lighter;
            letter-spacing: $letter-spacing;
            margin-bottom: 5px;
            margin-top: 10px;
        }
        p {
            font-size: 0.750em;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: $letter-spacing;
            margin-bottom: 0px;
        }
    }
    .header-logo,
    .header-logo:active {
        color: $black;
        text-decoration: none;
    }
}

.back-btn.custom {
    display: none;
    position: absolute;
    bottom: unset;
    left: 50px;
    z-index: 3000000000;
    top: 94px;
    width: 200px;
    border-width: 0 !important;
    img {
        width: 22px;
    }
}

.back-btn.manual {
    position: absolute;
    bottom: unset;
    left: 50px;
    z-index: 3000000000;
    top: 62px;
    width: 200px;
    border-width: 0 !important;
    margin-top: 0px;
    img {
        width: 22px;
    }
}

.optin-form-loading {
    h2 {
        font-family: $raleway;
        font-size: 3em;
        color: black;
        letter-spacing: 2px;
        font-weight: 500;
    }
}

.assessment_div {
    #get_started {
        background-color: $btn;
        border-radius: 100px;
        padding: 10px 30px;
        font-size: 1em;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        border: none;
        letter-spacing: $letter-spacing-2;
        font-family: $raleway;
        background-color: $question-blue;
        outline: none;
    }
    #section1,
    #section2 {
        label {
            border: 2px solid $question-blue;
            color: $question-blue;
            font-size: 16px;
            font-family: $raleway;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 2px;
            margin: 20px 10px 0px 10px;
            padding: 10px 20px;
            height: auto;
            width: 230px;
            &:hover {
                background-color: $question-blue;
            }
        }
        label.age {
            width: 150px;
        }
        label.label-unknown {
            width: 230px;
            font-size: 16px;
            font-family: $noto;
            letter-spacing: 0px;
            font-weight: 400;
            text-transform: initial;
            margin-top: 10px;
            color: black;
            &:hover {
                background-color: $question-blue;
                color: white;
            }
        }
        #age {
            border: 2px solid #5587a2;
            font-size: 16px;
            line-height: normal;
            width: 150px;
            margin-top: 15px;
            padding: 10px 0px;
            text-align: center;
            outline: 0;
            border-radius: 0px;
            margin-top: 15px;
            color: black;
            font-family: $raleway;
        }
        label.label-unknown.label-checked {
            background-color: $question-blue;
            color: white;
            text-decoration: none;
        }
    }
    .section {
        h1 {
            font-size: 18px;
            font-family: $raleway;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .hello-container {
        .first-set {
            span.first {
                margin-bottom: 30px;
            }
        }
    }
    h1.title {
        line-height: 1.3em;
        span {
            font-weight: 600;
        }
        strong {
            font-size: 62px;
            font-weight: 500;
            font-family: $raleway;
            letter-spacing: 2px;
            text-transform: initial;
        }
        .first-set {
            .first {
                padding-bottom: 30px;
            }
        }
    }
}

div#progressbar-start,
div#progressbar {
    padding: 6px 10px;
}

.ui-widget.ui-widget-content {
    background-color: #fff7de;
    border: none;
}

.ui-progressbar-value.ui-corner-left.ui-widget-header {
    border: none;
    background-color: $progress-gold;
}

.percent-wrap {
    .percent {
        color: black;
        font-size: 14px;
        font-weight: 500;
        font-family: $raleway;
        letter-spacing: 2px;
    }
}

.section_1 {
    background-color: $blue;
    width: 100%;
    padding: 50px 0px;
    background: url('../images/homepage/blue.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 72%;
    color: $black;
    .section_1_left {
        text-align: center;
        h2 {
            font-size: 3.875em;
            line-height: 1em;
        }
        p {
            text-transform: uppercase;
            font-size: 1.125em;
            font-family: $raleway;
            letter-spacing: $letter-spacing;
            margin-top: 30px;
        }
        button {
            margin-top: 30px;
            margin-left: -25px;
        }
    }
}

.section_2 {
    padding: 0px 0px 50px 0px;
    color: $black;
    text-align: center;
    h2 {
        line-height: 1.2em;
    }
    p {
        font-family: $noto;
        font-size: 1em;
        margin-bottom: 30px;
    }
    ul {
        list-style: none;
        padding: 0px;
        margin-top: 50px;
        margin-bottom: 50px;
        li {
            display: inline-block;
            width: 20%;
            h4 {
                font-size: 1.125em;
                letter-spacing: $letter-spacing-2;
                text-transform: uppercase;
                margin: 30px 0px 10px 0px;
            }
            p {
                font-size: 1em;
                font-family: $noto;
            }
        }
    }
    hr {
        margin: 50px 0px;
        border: 0.5px solid $black;
        height: 0px;
    }
    .caps {
        text-transform: uppercase;
        font-family: $raleway !important;
        font-size: 1em;
        margin-top: 20px;
        letter-spacing: $letter-spacing;
        font-weight: 500;
    }
}

.section_3 {
    color: $black;
    padding: 50px 30px;
    height: 500px;
    background: url('../images/homepage/blue.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 97%;
    p {
        font-size: 1.125em;
        font-family: $raleway;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
    }
    ul {
        margin-top: 50px;
        li {
            font-size: 1em;
            font-family: $noto;
            margin-bottom: 20px;
        }
    }
    .section_3_italic {
        font-family: $noto;
        font-size: 1em;
        text-transform: initial;
        margin: 30px 0px;
        font-style: italic;
        letter-spacing: 0;
    }
    .section_3_container {
        text-align: center;
        button {
            padding: 10px 30px;
        }
    }
    .image-placeholder {
        width: 100%;
        display: block;
        max-width: 600px;
        float: right;
        margin-top: -35px;
        img {
            width: 100%;
        }
    }
    .section_3_content {
        max-width: 600px;
        float: left;
    }
}

.section_4 {
    text-align: center;
    color: $black;
    padding: 72px 0px;
    h2 {
        line-height: 1.2em;
    }
    p {
        font-size: 1em;
        font-family: $noto;
        margin: 30px 0px;
    }
    .section_4_brain_scans {
        margin-top: 30px;
        p {
            font-size: 1.125em;
            font-family: $raleway;
            letter-spacing: 2px;
            margin-top: 10px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    hr {
        margin: 50px 0px;
        border: 0.5px solid $black;
        height: 0px;
    }
}

.section_5 {
    padding: 50px;
    color: $black;
    text-align: center;
    p {
        font-size: 1.125em;
        line-height: 1.3em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        margin: 30px 0px 50px 0px;
    }
    .section_5_icons {
        p {
            margin: 0px;
            font-family: $noto;
            font-size: 1em;
            line-height: 1.3em;
            text-transform: initial;
            margin-top: 30px;
            letter-spacing: 1px;
            font-weight: 500;
        }
    }
}

.section_6 {
    padding: 50px 0px;
    overflow: hidden;
    color: $black;
    [class*="col-"] {
        margin-bottom: -99999px;
        padding-bottom: 99999px;
    }
    .section_6_content {
        text-align: center;
        margin-top: 75px;
        .quote {
            display: inline;
            font-size: 1.2em;
        }
    }
    h2 {
        line-height: 1.2em;
        letter-spacing: $letter-spacing;
    }
    p {
        font-size: 1.125em;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-2;
        font-family: $raleway;
        margin-top: 30px;
        span {
            font-family: $noto;
            font-size: 0.889em;
            text-transform: initial;
            font-weight: 300;
            display: block;
            margin-top: 20px;
            margin-bottom: 30px;
            letter-spacing: 1px;
        }
    }
}

.section_7 {
    padding: 30px;
    background-color: #e1e1e1;
    text-align: center;
    color: $black;
    h4 {
        font-size: 1.125em;
        font-weight: bold;
        letter-spacing: $letter-spacing;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    p {
        width: 100%;
        font-size: 0.875em;
        font-family: $noto;
        margin: 0 auto;
        line-height: 1.8em;
    }
}

.section_8 {
    width: 100%;
    padding: 50px 0px;
    font-family: $raleway;
    ul {
        list-style: none;
        padding-left: 0px;
        width: 100%;
        li {
            display: inline;
            margin: 0px 20px;
        }
    }
    p {
        font-size: 1.125em;
        font-weight: 400;
        margin-top: 50px;
        text-align: center;
    }
}

.results-page {
    color: $black;
    .cap {
        font-weight: 500;
        font-family: $raleway;
        letter-spacing: $letter-spacing-2;
        margin-top: 30px;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-size: 1.125em;
    }
}

.result_section_1 {
    color: $black;
    padding: 50px 0px;
    text-align: center;
    background-color: #edf4f7;
    h2 {
        font-size: 3.875em;
        font-family: $raleway;
    }
    p {
        font-size: 1em;
        font-family: $noto;
        line-height: 1.5em;
    }
}

.result_section_2 {
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: space-between;
    .result_section_2_parts {
        width: 48%;
        text-align: center;
        h2 {
            font-size: 3.000em;
            font-family: $raleway;
            line-height: 1.2em;
            margin-bottom: 0px;
        }
        .description_list {
            font-family: $noto;
            font-size: 1em;
            line-height: 1.5em;
            margin-bottom: 30px;
        }
        .image-placeholder {
            width: 100%;
            max-width: 400px;
            height: 400px;
            background: url('../images/homepage/ring.png');
            background-repeat: no-repeat;
            background-position: center center;
            margin: 0 auto;
            .bt {
                margin: 0px;
                padding: 0px;
                text-align: center;
                color: #8fb8cd;
                font-size: 180px;
                font-weight: 600;
                display: block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.result_section_3 {
    padding: 50px 0px;
    background: url('../images/homepage/blue.png');
    background-repeat: no-repeat;
    background-size: 100% 450px;
    background-position: top left;
    text-align: center;
    .image-boxes {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-between;
        margin-top: 50px;
        .image-box {
            padding: 10px 14px;
            width: 25%;
            margin: 20px;
            max-width: 360px;
            position: relative;
            height: 820px;
            p {
                font-size: 1em;
                font-family: $noto;
                line-height: 1.5em;
            }
            .cap {
                font-size: 1.125em;
                font-family: $raleway;
            }
            button {
                position: absolute;
                display: block;
                bottom: 35px;
                left: 50%;
                transform: translateX(-50%);
                width: 300px;
            }
        }
        .image-box-1 {
            -webkit-box-shadow: 8px 8px 0px 0px rgba(228, 146, 95, 1);
            -moz-box-shadow: 8px 8px 0px 0px rgba(228, 146, 95, 1);
            box-shadow: 8px 8px 0px 0px rgba(228, 146, 95, 1);
            background-color: white;
            button {
                background-color: rgba(228, 146, 95, 1);
            }
        }
        .image-box-2 {
            -webkit-box-shadow: 8px 8px 0px 0px rgba(67, 150, 180, 1);
            -moz-box-shadow: 8px 8px 0px 0px rgba(67, 150, 180, 1);
            box-shadow: 8px 8px 0px 0px rgba(67, 150, 180, 1);
            background-color: white;
            button {
                background-color: rgba(67, 150, 180, 1);
            }
            .image-placeholder {
                margin-bottom: 40px;
            }
        }
        .image-box-3 {
            -webkit-box-shadow: 8px 8px 0px 0px rgba(122, 181, 160, 1);
            -moz-box-shadow: 8px 8px 0px 0px rgba(122, 181, 160, 1);
            box-shadow: 8px 8px 0px 0px rgba(122, 181, 160, 1);
            background-color: white;
            button {
                background-color: rgba(122, 181, 160, 1);
            }
            .image-placeholder {
                margin-bottom: 50px;
            }
        }
    }
    .image-placeholder {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        height: auto;
        img {
            width: 100%;
            height: auto;
        }
        .aci-play,
        .bfl-play {
            cursor: pointer;
        }
    }
}

.result_section_4 {
    padding: 50px 0px;
    color: $black;
    text-align: center;
    .social {
        ul {
            padding-left: 0px;
            margin-bottom: 0px;
            li {
                display: inline-block;
                margin: 20px 25px;
            }
        }
    }
}

.result_section_5 {
    padding: 50px 0px;
    color: $black;
    background-color: #edf4f7;
    text-align: center;
    p {
        font-size: 1em;
        font-family: $noto;
        line-height: 1.5em;
    }
    .cap {
        margin-top: 0px;
    }
    a {
        color: black;
        text-decoration: none;
        &:hover,
        &:active {
            color: $black;
        }
    }
}

.result_section_6 {
    padding: 50px 0px;
    text-align: center;
    .disclaimer {
        text-align: center;
        color: #898989;
        margin: 20px 0px 50px 0px;
        .small {
            font-size: 0.750em;
        }
    }
    ul {
        padding-left: 0px;
        list-style: none;
        width: 100%;
        margin: 0 auto;
        li {
            display: inline-block;
            margin: 0px 10px;
            img {
                margin: 0 auto;
            }
        }
    }
    .powered {
        font-family: $raleway;
        font-size: 1.125em;
    }
}

.section_optin_message {
    width: 100%;
    padding: 50px 0px;
    text-align: center;
    font-family: $raleway;
    color: black;
    h2 {
        line-height: 1.2em;
        font-size: 4em;
        margin-bottom: 0px;
    }
    p {
        font-family: $noto;
        font-size: 1em;
        line-height: 2em;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .cap {
        text-transform: uppercase;
        font-family: $raleway;
        font-size: 1em;
        letter-spacing: $letter-spacing-2;
        font-weight: 500;
        margin-top: 0px;
    }
}

.section-optin-main {
    padding: 0px;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 149px;
}

.section-optin {
    margin-top: 100px;
    .text-danger {
        font-size: 0.7em;
        text-align: left;
    }
}

.section_optin_form {
    margin-top: 30px;
    .italic-disclaimer {
        font-size: 14px;
        font-family: $noto;
        color: black;
        width: 70%;
        margin: 0 auto;
        font-style: italic;
        font-weight: lighter;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .optin-form {
        width: 550px;
        margin: 0 auto;
        color: black;
        .optin_input {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        label {
            letter-spacing: 2px;
            font-size: 18px;
            font-family: $raleway;
            font-weight: 500;
            text-transform: uppercase;
            margin-right: 10px;
            margin-bottom: 5px;
        }
        input {
            border: none;
            border-bottom: 2px solid black;
            font-size: 18px;
            font-family: $raleway;
            outline: none;
        }
        input[name="name"] {
            width: 358px;
        }
        input[name="email"] {
            width: 415px;
        }
        .checkbox-field {
            text-align: left;
            margin-bottom: 0px;
            label {
                font-size: 16px;
                font-family: $noto;
                color: black;
                font-weight: 400;
                text-transform: initial;
                letter-spacing: 0px;
            }
        }
        .checkbox-field.second {
            margin-left: 0px;
        }
        .form-fields {
            .checkbox-field {
                .checkbox-symbol {
                    width: 24px;
                }
            }
        }
        .optin_checkbox {
            margin-top: 30px;
        }
        .form-buttons {
            margin-top: 30px;
        }
    }
}

.lang-hu {
    .section_optin_form .optin-form input[name=email] {
        width: 322px !important;
    }
    .section_optin_form .optin-form .checkbox-field label {
        font-size: 13px !important;
    }
    .result_section_3 .image-boxes .image-box {
        height: 800px;
    }
    .result_section_3 .image-boxes .image-box-1 button {
        width: 300px;
    }
    .result_section_3 .image-boxes .image-box {
        width: 30%;
        max-width: 500px;
    }
}

@media screen and (max-width: 1024px) {
    .back-btn.custom {
        top: 5.3%;
    }
    .back-btn.manual {
        top: 2.5%;
    }
    .result_section_3 {
        .image-boxes {
            .image-box {
                width: 100%;
            }
        }
    }
    .section-optin {
        margin-top: 0px !important;
    }
    .section_3 {
        height: auto;
        background-size: 100% 100%;
    }
    .section_5 {
        .section_5_icons {
            p {
                br {
                    display: none;
                }
            }
        }
    }
    .section_8 {
        ul {
            li {
                .social-bmd,
                .social-ac {
                    width: 140px;
                }
                .social-bfl,
                .social-au {
                    width: 220px;
                }
            }
        }
    }
    .result_section_6 {
        ul {
            li {
                margin: 0px 20px;
                ;
                .social-bmd,
                .social-ac {
                    width: 120px;
                }
                .social-bfl,
                .social-au {
                    width: 200px;
                }
            }
        }
    }
    .lang-hu {
        .lang-hu .result_section_3 .image-boxes .image-box {
            height: 800px;
            width: 50%;
            max-width: 500px;
        }
    }
}

@media screen and (max-width: 768px) {
    body {
        font-size: 14px;
    }
    br {
        display: none;
    }
    h2 {
        font-size: 2.5em;
    }
    .back-btn.manual {
        top: 5%;
    }
    .assessment_div .floating-controls .float-close-btn {
        top: 45px;
    }
    .back-btn.custom {
        top: 9.3%;
    }
    .section_1,
    .section_2,
    .section_3,
    .section_4,
    .section_5,
    .section_6,
    .section_7,
    .section_8 {
        padding: 20px;
    }
    .section_1 {
        height: auto;
        background-size: 100% 100%;
        #people-slideshow {
            margin-top: 20px;
            .item {
                width: 100%;
            }
        }
        .section_1_left {
            button {
                margin-left: 0px;
            }
        }
    }
    .section_2 {
        ul {
            li {
                width: 45%;
            }
        }
    }
    .section_3 {
        height: auto;
        background-size: 100% 100%;
        .image-placeholder {
            margin-bottom: 20px;
            float: none;
            width: 100%;
            max-width: 100%;
        }
        .section_3_content {
            max-width: 100%;
            float: none;
        }
    }
    .section_4 {
        hr {
            margin: 20px 0px;
        }
    }
    .section_5_icons {
        div {
            margin-bottom: 30px;
            width: 30%;
            margin-left: 1%;
            margin-right: 1%;
        }
    }
    .section_6 {
        .section_6_content {
            margin-top: 0px;
        }
        img {
            margin: 0 auto;
            margin-top: 20px;
            display: block;
        }
    }
    .section_8 {
        padding: 20px;
        ul {
            li {
                margin: 0px;
                .social-bmd,
                .social-ac {
                    width: 120px;
                }
                .social-bfl,
                .social-au {
                    width: 200px;
                }
            }
        }
        p {
            margin-top: 30px;
        }
    }
    .result_section_1,
    .result_section_2,
    .result_section_3,
    .result_section_4,
    .result_section_5,
    .result_section_6 {
        padding: 20px;
    }
    .result_section_2 {
        .result_section_2_parts {
            width: 100%;
            text-align: center;
            .image-placeholder {
                height: 400px;
            }
        }
        .result_section_2_parts_right {
            padding: 0px;
        }
        .description_list {
            br {
                display: block;
            }
        }
    }
    .result_section_3 {
        height: auto;
        background-size: 100% 100%;
        .image-boxes {
            margin-top: 0px;
            .image-box {
                width: 100%;
                height: 600px !important;
                button {
                    bottom: 15px;
                }
            }
        }
    }
    .result_section_6 {
        ul {
            li {
                margin: 0px;
                .social-bmd,
                .social-ac {
                    width: 120px;
                }
                .social-bfl,
                .social-au {
                    width: 200px;
                }
            }
        }
        .disclaimer {
            margin: 30px 0px;
        }
    }
    .section-optin {
        margin-top: 165px !important;
    }
    .lang-hu {
        .result_section_3 .image-boxes .image-box {
            width: 100%;
            height: 700px!important;
        }
    }
}

@media screen and (max-width: 480px) {
    header {
        .logo-container {
            h1 {
                margin-top: 5px;
            }
        }
    }
    .optin-form-loading {
        h2 {
            line-height: 1em;
        }
    }
    .assessment_div {
        #section1,
        #section2 {
            label {
                margin: 20px auto 0px auto;
                padding: 5px 20px;
                font-size: 14px;
            }
        }
        .progressbar-wrap {
            width: 85% !important;
            .percent-wrap {
                span.last {
                    right: 0px !important;
                }
            }
        }
        .main-slide h1 {
            font-size: 16px !important;
            margin-bottom: 20px;
        }
        .question-text {
            font-size: 20px !important
        }
        #get_started {
            margin: 0px;
            margin-top: 20px;
            width: 100%;
            font-size: 0.8em;
            text-align: center;
        }
        .floating-controls {
            .float-close-btn {
                position: fixed;
                top: 15px;
                right: 30px;
                z-index: 1000000000;
                font-size: 18px;
                color: black;
                font-weight: 300;
            }
        }
        .back-btn.custom {
            top: 8%;
            left: 30px;
            img {
                width: 18px;
            }
        }
    }
    .section-optin {
        margin-top: 115px !important;
        .optin-form-loading {
            padding: 0px 10px;
        }
        .section_optin_message {
            padding: 10px;
            .container {
                padding-left: 0px;
                padding-right: 0px;
            }
            h2 {
                font-size: 2em;
            }
            .cap {
                font-size: 0.8em;
                line-height: 1.5em;
            }
            p {
                font-size: 0.8em;
                line-height: 1.5em;
            }
        }
        .section_optin_form {
            margin-top: 0px;
            .optin-form {
                padding-top: 20px;
                width: 100%;
                label {
                    font-size: 12px;
                    margin-bottom: 0px;
                    margin-left: 20px;
                    text-indent: -18px;
                }
                input#name,
                input#email {
                    width: 100%;
                    padding: 0px;
                    font-size: 12px;
                }
                .optin_checkbox {
                    margin-top: -20px;
                    padding: 10px 20px;
                }
                .checkbox-field.second {
                    margin-left: 0px !important;
                }
                .form-buttons {
                    margin-top: -10px;
                }
                .checkbox-field {
                    .checkbox-symbol {
                        width: 15px;
                    }
                }
            }
            .italic-disclaimer {
                width: 100%;
                padding: 10px;
                margin-top: 0px;
                font-size: 12px;
            }
        }
    }
    .section_1 {
        #people-slideshow {
            .item {
                width: fit-content;
                float: none;
                text-align: center;
                color: white;
                height: auto;
                margin: 0 auto;
            }
        }
    }
    .section_2 {
        ul {
            li {
                width: 100%;
            }
        }
    }
    .section_5 {
        .section_5_icons {
            div {
                width: 100%;
            }
        }
    }
    .section_8 {
        ul {
            li {
                display: block;
                text-align: center;
                margin-bottom: 10px;
                .social-bmd,
                .social-ac {
                    width: 140px;
                }
            }
        }
    }
    .result_section_2 {
        .result_section_2_parts {
            .image-placeholder {
                width: 300px;
                height: 300px;
                background-size: cover;
                .bt {
                    font-size: 150px;
                }
            }
        }
    }
    .result_section_4 {
        .social {
            ul {
                li {
                    margin: 15px;
                    img {
                        width: 48px;
                    }
                }
            }
        }
    }
    .result_section_6 {
        ul {
            li {
                display: block;
                margin-bottom: 10px;
                .social-bmd,
                .social-ac {
                    width: 140px;
                }
            }
        }
        .powered {
            font-size: 0.8em;
        }
    }
    .back-btn.manual {
        top: 0px;
        position: fixed;
        left: 20px;
        width: 16px;
    }
    .section-optin-error {
        .optin-form {
            margin-top: -150px;
        }
    }
    .lang-hu {
        .result_section_3 .image-boxes .image-box-1 button {
            width: 260px;
        }
        .result_section_3 .image-boxes .image-box {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

@media screen and (max-height: 736px) {
    .assessment_div {
        margin-top: -120px;
    }
}

@media screen and (max-height: 568px) {
    .assessment_div .main-slide h1 {
        font-size: 12px !important;
        margin-bottom: 20px;
    }
    .assessment_div #section1 label,
    .assessment_div #section2 label {
        margin: 20px auto 0px auto;
        padding: 2px 20px;
        font-size: 13px;
    }
    .progressbar-wrap {
        position: absolute;
        top: 74%;
        bottom: 0;
        margin: 0 auto;
        width: 45%;
        right: 0;
        left: 0;
    }
}

@media screen and (max-width: 375px) {
    .section-optin .section_optin_message .cap {
        font-size: 0.8em;
        line-height: 1.5em;
    }
    .section-optin .section_optin_message p {
        font-size: 0.6em;
        line-height: 1.5em;
    }
    .optin-form .form-fields .checkbox-field label {
        font-size: 10px !important;
        width: 100%;
    }
    .section-optin .section_optin_form .italic-disclaimer {
        width: 100%;
        padding: 10px;
        margin-top: 0px;
        font-size: 6px;
    }
}